module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-174942381-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PatrickPuga","short_name":"PatrickPuga","start_url":"/","display":"minimal-ui"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
